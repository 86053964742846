import React , {useMemo} from 'react';


 const useGetPagesArray =  (limit,totalPages)=> { 
    const pagesArray=
            useMemo(
            ()=>{   
                let pagesArray=[];
                
                for (let i=0; i<totalPages; i++) {
                pagesArray.push(i+1);
                console.log(pagesArray)

                }
                return pagesArray;

                },[limit,totalPages]);

                return pagesArray;

}

export  default useGetPagesArray;