import React, { useState, useEffect } from "react";

import PostList from "../components/PostList";
import "../styles/App.css";
import PostForm from "../components/PostForm";
import MyButton from "../components/UI/button/MyButton";

import PostFilter from "../components/PostFilter";
import MyModal from "../components/UI/MyModal/MyModal";
import { usePosts } from "../hooks/useSortedPosts";
import PostService from "../API/PostService";
import Loader from "../components/UI/Loader/Loader";
import { useFetching } from "../hooks/useFetching";
import { getPageCount } from "../utils/pages";
import useGetPagesArray from '../hooks/usePagination'
import Pagination from "../components/UI/pagination/Pagination";


function Posts() {
  const [posts,setPosts]=useState([
    // {
    //   id:1,
    //   title:"Javascript",
    //   body:"Javascript- язык программирования"
    // },
    // {
    //   id:2,
    //   title:"Javascript 2",
    //   body:"Javascript- язык программирования"
    // },
    // {
    //   id:3,
    //   title:"Javascript 3",
    //   body:"Javascript- язык программирования"
    // },
      
  ]); 

  const [filter,setFilter]=useState({sort:'',query:""});
  const [modal,setModal]=useState(false);
  const [totalPages, setTotalPages]=useState(0);
  const [limit, setLimit]=useState(10);
  const [page, setPage]=useState(1);
  const sortedAndSearchedPosts=usePosts(posts,filter.sort,filter.query);
  let pagesArray=useGetPagesArray(limit,totalPages);
  const  [fetchPosts, isPostLoading, postError]=useFetching(async ()=>{
    const response = await PostService.getAll(limit,page);
    setPosts(response.data);
   const totalCount=(response.headers['x-total-count']);
    setTotalPages(getPageCount(totalCount,limit))
  })

 useEffect(()=>{fetchPosts(); return}
 , [page])

    const createPost=(newPost)=>{
      setPosts([...posts,newPost]);
      setModal(false);
    }
  
    // async function fetchPosts(){
    //     const response= await axios.get('https://jsonplaceholder.typicode.com/posts');
    //     console.log(response.data);
    // }

  //    async function fetchPosts(){
  //     setIsPostLoading(true);
  //    const posts = await PostService.getAll();
  //    setPosts(posts);
  //    setIsPostLoading(false);     
  // }

    const removePost=(post)=>{
      setPosts(posts.filter(p=>p.id!==post.id))
      } 



  return (    

  <div className="App"> 
  <button onClick={fetchPosts}>Get Posts</button>
     <MyButton style={{marginTop:'15px',marginBottom:'15px'}} onClick={()=>setModal(true)}>
       Создать пост
     </MyButton>
    <MyModal visible={modal} setVisible={setModal}>
         <PostForm create={createPost}/>
    </MyModal>

  {/* <hr style={{margin:'15px 0'}}/> */}
    <PostFilter filter={filter}
     setFilter={setFilter}
    />
      {postError && 
        <h1>Ошибка {postError}</h1>
      }
     { isPostLoading 
        ? <div  style ={{display:'flex', justifyContent:'center', marginTop:'50px'}}><Loader/></div>  
        : <PostList remove={removePost} posts={sortedAndSearchedPosts} title={'Список постов про JS'}/>
     }
      
      <Pagination 
      page={page} 
      pagesArray={pagesArray}
      setPage={setPage}
      />

  </div> )

}

export default Posts;