import React, { useState, useEffect } from "react";
import { BrowserRouter,Switch,Route,Link,Redirect} from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";

import NavBar from "./components/UI/NavBar/NavBar";
import AppRouter from "./components/AppRouter";


function App() {
return ( 
    <BrowserRouter>
    <CompatRouter>
      <NavBar/>
      <AppRouter/>
    </CompatRouter>    
    </BrowserRouter>

 
  )
}

export default App;
