import React from 'react'
import './Pagination.module.css'

export default function Pagination({page,pagesArray,setPage}) {
  return (
    <div className='page__wrapper'>
    { 
        pagesArray.map(p=>
        <span 
        key={p} 
        onClick={()=>{setPage(p);}} 
        className={page===p ?'page page__current':'page'}>
          {p}
        </span>     
        )
     }
    </div>
  )
}
