import React,{useState} from "react";
import MyButton from "./UI/button/MyButton";
import MyInput from "./UI/input/MyInput";

export default function PostForm({create}) {
    const [post,setPost]=useState({title:"",body:""})
    
    const addNewPost=(e)=>{
        e.preventDefault();
        const newPost={
            ...post,id:Date.now()
        }
        create(newPost);
        setPost({title:"",body:""});
        }


  return (
    <form action="#">
    <MyInput
    value={post.title}
    onChange={e=>setPost({...post,title:e.target.value})}
    type="text" 
    placeholder="Название поста"
    />

    
    <MyInput 
    value={post.body}
    onChange={e=>setPost({...post, body : e.target.value})}
    type="text" 
    placeholder="Описание поста"
    />

{/* <input type="text" 
ref={bodyInputRef}
/> */}

    <MyButton style={{color:"red"}} onClick={addNewPost}>
      Создать новый пост
    </MyButton>

  </form>
  )
}
