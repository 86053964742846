import React, { useState, useEffect } from "react";




function App() {
return (
  <h1>
        Это приложение создано
  </h1> 
  )
}

export default App;