import React from 'react'

export default function Error() {
  return (
    <div>
        <h1 style={{color:"red"}}>
            Этой страницы не существует
        </h1>
    </div>
  )
}
